// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import { SEO, Title, Text, Intro, BlocksList } from "components";

import img1 from "../images/illustrazioni/ascolto.png";
import img2 from "../images/illustrazioni/analisi.png";
import img3 from "../images/illustrazioni/delivery.png";
import img4 from "../images/illustrazioni/vittoria.png";

import img5 from "../images/illustrazioni/processo.png";
import img6 from "../images/illustrazioni/business.png";
import img7 from "../images/illustrazioni/strategia.png";

import img8 from "../images/illustrazioni/web_marketing.png";
import img9 from "../images/illustrazioni/annuncio.png";
import img10 from "../images/illustrazioni/scatola.png";
import img11 from "../images/illustrazioni/direct_mail_marketing.png";
import img12 from "../images/illustrazioni/comunicazione.png";

const pageProperties = {
  title: "Strategia e servizi di Web Marketing data-driven per la crescita aziendale",
  description:
    "Vuoi trovare nuovi clienti su internet? Ottieni risultati online con una strategia digitale concreta e tracciabile, creata su misura per la tua azienda.",
};

const analytics_blocks = [
  {
    title: "Chi sono i visitatori del tuo sito",
    description: `Attraverso i numeri del tuo sito o dei tuoi social puoi sapere tante cose sui tuoi clienti: dati anagrafici, provenienza, parole chiave utilizzate per trovarti. Tutto questo sostituisce costose indagini di mercato offline.`,
    image: img1,
  },
  {
    title: "Cosa fanno i visitatori nel tuo sito",
    description: `Sapere cosa fanno i tuoi utenti da quando arrivano sul sito a quando comprano, è fondamentale. Potresti scoprire percorsi di navigazione che non ti aspettavi e correggere punti deboli dove gli utenti abbandonano il sito.`,
    image: img2,
  },
  {
    title: "Tasso di conversione",
    description: `Su internet non conta solo quanto stai vendendo, ma come stai vendendo. Stai sfruttando al massimo il traffico del tuo sito?
        Attraverso gli analytics puoi sapere con esattezza se le tue vendite sono sottodimensionate rispetto alle tue potenzialità, se possono crescere o se stai performando al massimo.`,
    image: img3,
  },
  {
    title: "Quanto sono efficaci le tue azioni di web marketing",
    description: `Qualsiasi azione tu metta in atto può essere misurata con esattezza.
Che si parli di mail marketing, di e-commerce, di advertising, potrai sapere sempre come sta performando la tua iniziativa grazie ad un buon sistema di analytics.`,
    image: img4,
  },
];

const blocks = [
  {
    title: "Strategia digitale",
    description: `Se il tuo e-commerce, il tuo sito web o i tuoi social non hanno i risultati che ti aspettavi, è giunto il momento di definire un piano strategico di correzioni e crescita.
I nostri esperti ti affiancheranno nel definire una strategia e nella sua attuazione, per qualsiasi area digitale della tua azienda.`,
    image: img5,
  },
  {
    title: "Marketplace e vendita online",
    description: `Se vuoi iniziare a vendere online non è detto che un e-commerce sia l’investimento giusto per la tua attività.
Rivolgendoti a noi puoi valutare se il tuo progetto o il tuo prodotto sono pronti per essere venduti online, ma soprattutto quale può essere lo strumento più adatto.
Ebay, Etsy, Amazon Marketplace potrebbero rivelarsi più adeguati e noi ti seguiremo nella loro configurazione`,
    image: img6,
  },
  {
    title: "Statistiche e Report",
    description: `Non avere i numeri della propria azienda sotto controllo, oggi è un gran problema. Scegliamo, configuriamo e analizziamo gli strumenti di web analytics per misurare l’efficacia del tuo business.`,
    image: img7,
  },
  {
    title: "Consulenza e-commerce",
    description: `Possiamo affiancare la tua azienda nella gestione e nella promozione del tuo negozio online. Un e-commerce è un insieme di tanti aspetti e spesso molti di questi vengono trascurati o ignorati da chi ve lo ha realizzato.
Il primo passo per iniziare a vendere è cominciare a pensare il vostro ecommerce come un vero e proprio ramo aziendale.
La nostra esperienza può farvi fare il salto di qualità`,
    image: img8,
  },
  {
    title: "Advertising",
    description: `Anni di studio, con certificazioni, ma soprattutto di esperienza sul campo ci hanno permesso di poter spaziare su tutte le principali piattaforme di annunci presenti in rete. Ti aiuteremo a scegliere ad esempio tra Google Ads o Facebook. Definiremo quale strategia e quanto investire. Configureremo le tue campagne.`,
    image: img9,
  },
  {
    title: "Privacy e GDPR",
    description: `In seguito al regolamento ue 679/16 qualsiasi tipo di azione di marketing deve essere seguita da una adeguata attenzione alla privacy e al rispetto della legge europea. Mettendo insieme un legale specializzato e i nostri tecnici, forniamo consulenze su ogni aspetto del vostro business online.`,
    image: img10,
  },
  {
    title: "Direct Email Marketing",
    description: `Attraverso una gestione automatizzata delle email che invii ai tuoi clienti puoi ottenere risultati strepitosi.
Ci occupiamo di configurare e integrare nel tuo sito gli strumenti giusti per fare campagne via email fornendoti le tue statistiche.
Possiamo creare complessi sistemi di newsletter, di suggerimenti post vendita, fidelizzazione della clientela, acquisizione del cliente (lead generation).`,
    image: img11,
  },
  {
    title: "Audit e consulenza",
    description: `Hai già un progetto avviato, un sito internet, un profilo social ma non sei sicuro che i vostri annunci o la vostra presenza online stia andando nella direzione giusta? Possiamo effettuare una consulenza`,
    image: img12,
  },    
];

const numbered_blocks = [
  {
    title: "Analisi Preliminare",
    description: `La prima cosa che andremo ad eseguire è un audit tecnico e insieme definiremo gli obiettivi del progetto.
    Facciamo sempre una “fotografia” analitica della vostra situazione di partenza, definendo quindi il punto di partenza su cui misurare miglioramenti e cambiamenti futuri.`,
  },
  {
    title: "Scelta della strategia e degli strumenti",
    description: `Non esiste lo strumento perfetto. Dopo un’attenta analisi della vostra situazione, in base al budget disponibile, siamo in grado di decidere quali azioni e quali strumenti utilizzare per massimizzare i risultati.`,
  },
  {
    title: "Esecuzione strategia",
    description: `Grazie ad un team con esperienza decennale nel settore digitale, metteremo in pratica quanto definito o affiancheremo i vostri tecnici per controllare la corretta implementazione sui vostri siti o dispositivi.`,
  },
  {
    title: "Misurazione dei risultati ottenuti",
    description: `Grazie agli strumenti di misurazione configurati vi forniremo i report sull’andamento del vostro progetto.`,
  },
  {
    title: "Correzione e ottimizzazione",
    description: `Confrontando i nuovi dati con quelli iniziali siamo in grado di valutare se la strategia sta dando i risultati sperati o necessita di correzioni.`,
  },
  {
    title: "Espansione",
    description: `Una volta che il processo è stabile e misurato in ogni suo punto è il momento di crescere: potremo aggiungere elementi alla strategia o aumentare l’investimento pubblicitario mantenendo un processo controllato e con molti meno rischi.`,
  },
];


function WebMarketingAnalyticsPage() {
  return (
    <CleanLayout>
      <SEO
        title="Strategie web marketing e web analytics a Prato, Firenze e Pistoia"
        description="Il tuo sito non vende? Trame Digitali è una web agency di Prato, specializzata in strategie e soluzioni di web marketing basate sui numeri."
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen ">
        <section className="px-8 md:px-28">
          <Intro {...pageProperties} />
        </section>

        <section className="intro-page">
            <div className="flex flex-col items-left px-8 md:px-28 py-16" style={{ 'column-count': 2, 'column-gap': '30px' }} >
                Promuovere la propria attività su internet ha vantaggi e offre strumenti che non esistono nel marketing classico. Il grande vantaggio è poter usufruire di dati e metriche che la pubblicità tradizionale non ha. Le nostre soluzioni sono tutte data-driven, guidate cioè dai numeri reali: vi guideremo passo passo attraverso la configurazione degli strumenti necessari alla raccolta dei dati, analizzeremo i vostri numeri e sulla base di questi decideremo insieme quali azioni intraprendere. Il risultato sarà una strategia che risponde alle vostre vere necessità, più efficace e con un risparmio economico notevole.
            </div>
        </section>

        <section className="lista-analytics">
          <Title headingLevel="h2">Cosa puoi scoprire attraverso i web analytics?</Title>
          <BlocksList blocks={analytics_blocks} type="images" />
        </section>
        
        <section className="lista-servizi">
          <Title headingLevel="h2">I nostri servizi di web marketing</Title>
          <BlocksList blocks={blocks} type="images" />
        </section>

        <section className="closurePage">
            <div className="flex flex-col items-left px-8 md:px-28 py-16">
                <Title headingLevel="h2">Hai scelto noi? Questo è il nostro metodo</Title>  
                <p>
                In Trame Digitali adottiamo l’approccio LEAN. Un sistema di sviluppo agile e snello che prevede trasparenza e comunicazione continua col cliente. Non vendiamo formule magiche, non vendiamo promesse che dopo mesi e mesi non vedono nessun progresso.
                <br/>
                Fin dall’inizio del nostro progetto definiremo i vari passaggi, chiari e precisi e a conclusione di ognuno di essi sarete chiamati a verificare quanto fatto.
                Mettere troppa carne sul fuoco e impiegare troppo tempo per la realizzazione del progetto è rischioso. Potreste di perdere di vista gli obiettivi principali e quanto definito all’inizio del progetto: noi pensiamo in grande, ma agiamo in piccolo.<br/>
                <strong> Solo quando ogni passaggio della tua strategia sarà consolidato e misurabile, aggiungeremo nuovi elementi per la crescita del tuo fatturato.</strong>
                </p>
                <BlocksList blocks={numbered_blocks} />
                
            </div>
        </section>
      </div>
    </CleanLayout>
  );
}

export default WebMarketingAnalyticsPage;
